import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Header from "../components/landing/header"
import Footer from "../components/landing/footer"
import { FaArrowLeft, FaSearch } from "react-icons/fa"
import { connect } from "react-redux"
import { Link } from "gatsby"
// import * as styles from "../styles/templates/.module.scss"

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      internalName
      title
      image {
        gatsbyImageData(quality: 100, height: 300, width: 300)
      }
      body {
        raw
      }
    }
    allContentfulBlogPost {
      edges {
        node {
          internalName
          title
          slug
          id
        }
      }
    }
  }
`

const BlogArticle = ({ darkMode, data }) => {
  const [search, setSearch] = useState("")
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  )
  const Text = ({ children }) => (
    <p className={` mb-10 ${darkMode ? "text-gray-300" : "text-gray-600"}`}>
      {children}
    </p>
  )

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text className="text-neutral-900">{children}</Text>
      ),
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <h2
            className={`my-4 h2 font-bold text-xl ${
              darkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            {children}
          </h2>
        )
      },
      // [BLOCKS.OL_LIST]: (node, children) => {
      //   return <p>{children}</p>
      // },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, body } = node.data.target

        return (
          <div>
            <GatsbyImage image={gatsbyImageData} alt={body} />
          </div>
        )
      },
    },
  }
  const blog = data.contentfulBlogPost

  const handleSearch = e => {
    e.preventDefault()
    setSearch(e.target.value)
  }
  const navbarHeight = "96px"
  const footerHeight = "68px"

  return (
    <div
      className={` ${
        darkMode
          ? "bg-gradient-to-b from-[#060525] via-[#02096E] via-[#030756]  via-[#130412]  via-[#050534] via-[#06041F] to-[#060317]"
          : "text-black bg-gradient-to-b from-white via-[#e2e2e2] to-white"
      }`}
    >
      <Header />
      <div
        className={`container px-5 mx-auto `}
        style={{
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight} - 20px )`,
        }}
      >
        <div className="flex flex-col ">
          <div className="flex gap-10">
            <div className="w-96 mt-10 pt-10 pl-5 hidden md:block mt-10">
              <div className=" pt-10 mt-10" />
              {/* Search Bar */}
              <div className="mb-3">
                <form className="flex items-center">
                  <FaSearch color={darkMode ? "white" : "black"} />
                  <input
                    type="text"
                    className={`ml-3 bg-transparent focus:outline-0 ${
                      darkMode ? "text-white" : "text-black"
                    }`}
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </form>
              </div>
              <hr
                className={`mb-8 ${
                  darkMode ? "opacity-50" : "opacity-50 border-black "
                }`}
              />
              {/* Sidebar */}
              {data.allContentfulBlogPost.edges
                .filter(({ node }) => {
                  return node.title.toLowerCase().includes(search.toLowerCase())
                })
                .slice(0, 10)
                .map(({ node }, idx) => {
                  return (
                    <div className="mb-3 border-white">
                      <Link
                        to={`/blog/${node.slug}`}
                        key={idx}
                        activeClassName={`border-l-4 border-gray-500 ${
                          darkMode
                            ? "text-gray-200 hover:text-gray-200"
                            : "text-zinc-900 hover:text-zinc-900"
                        }`}
                        className="text-gray-500 hover:text-gray-700 flex"
                      >
                        <span className=" ml-4 uppercase text-xs ">
                          {node.title}
                        </span>
                      </Link>
                    </div>
                  )
                })}
              {/* View All Articles */}
              <Link
                to="/blog"
                className={`text-white rounded flex items-center text-center gap-2 mt-5 text-sm font-semibold  ${
                  darkMode
                    ? "text-gray-200 hover:text-gray-400"
                    : "text-zinc-900 hover:text-zinc-500 "
                }`}
              >
                {/* <FaArrowLeft /> */}
                View All Articles
              </Link>
            </div>

            <div className="w-full h-full min-h-full">
              <div className="justify-start text-left">
                {/* Back */}
                <Link
                  to="/blog"
                  className={`${
                    darkMode ? "text-gray-200" : "text-black"
                  } rounded flex items-center text-center gap-2`}
                >
                  <FaArrowLeft />
                  Blog
                </Link>
              </div>
              <hr
                className={` ${
                  darkMode ? "opacity-80" : "opacity-50 border-black"
                } my-10 } `}
              />
              {/* <GatsbyImage
                image={blog.image.gatsbyImageData}
                alt={blog.title}
                // style={{ height: "300px", width: "300px" }}
              /> */}
              <h1
                className={`text-4xl font-bold capitalize pb-10 pt-8 ${
                  darkMode ? "text-gray-200" : "text-zinc-900"
                }`}
              >
                {blog.internalName}
              </h1>
              <div className="text-white">
                {renderRichText(blog.body, options)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5" />
      <Footer />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode,
  }
}

export default connect(mapStateToProps)(BlogArticle)
